import { AfterViewInit, Component } from '@angular/core';
import { UtilService } from './core/services/util.service';
declare const initMap: any; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'skill-share-rewamp';
  isLoader: boolean;
  constructor(private _us: UtilService) {
    this._us.isLoading.subscribe(res => {
      Promise.resolve().then(() => {
        this.isLoader = res;
      });
    });
  }
  ngAfterViewInit() {
    // initMap();  // Initialize the map after the view is fully loaded
  }
}

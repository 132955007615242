

export class ApiConstants {

  public static routes = {
    login: 'UserManagement/LoginPortal',
    signup: 'UserManagement/RegisterUser',
    sendResetCode: 'UserManagement/SendResetCode',
    verifyResetCode: 'UserManagement/VerifyResetCode',
    verifyEmailExists: 'UserManagement/VerifyEmailExists?email=',
    verifyUserName: 'UserManagement/verifyUserName?username=',
    addFavorite: 'UserManagement/AddFavorite',
    removeFavorite: 'UserManagement/RemoveFavorite',
    getUserProfileById: 'UserManagement/getUserProfileById?',
    getUserDetailbyId: 'UserManagement/getUserDetailbyid?',
    getFavorite: 'UserManagement/getFavorite',
    updateUserProfile: 'UserManagement/UpdateUserProfile',
    getFavoriteList: 'UserManagement/getFavoriteList?id=',

    getCategoriesList: 'SkilledServices/GetCategoriesList',
    getSubCategoriesList: 'SkilledServices/GetSubCategoriesList',
    getCitiesList: 'SkilledServices/GetCitiesList',
    GetCountriesList: 'SkilledServices/GetCountriesList',
    getAreaList: 'SkilledServices/GetAreaList',
    // postService: 'SkilledServices/PostService',
    // getServicesbyProviderId: 'SkilledServices/getServicesbyProviderID',
    // getProviderById: 'SkilledServices/findProviderProfileByID',
    getProvidersList : 'SkilledServices/GetProvidersList',
    getProvidersById: 'SkilledServices/GetProvidersById?',
    addBookingServiceGeneralInfo: 'SkilledServices/AddBookingServiceGeneralInfo',
    addBookingServiceScheduleInfo: 'SkilledServices/AddBookingServiceScheduleInfo',
    addBookingServicePaymentMethod: 'SkilledServices/AddBookingServicePaymentMethod',
    getFeaturedProvidersList: 'SkilledServices/GetFeaturedProvidersList',
    getFeaturedCategoriesList: 'SkilledServices/GetFeaturedCategoriesList',

    getJobDetailbyid: 'Jobs/GetJobDetailById?',
    getJobsList: 'Jobs/GetJobsList',
    getJobTypesList: 'Jobs/jobTypesList',
    applyJob: 'Jobs/ApplyJob',
    postJob: 'Jobs/PostJob',
    DeleteJob: 'Jobs/DeleteJob',
    getJobProposalById: 'Jobs/GetJobProposalById?id=',
    approveJobProposal:'Jobs/ApproveJobProposal',
    addFeedback: 'Jobs/AddFeedback',
    endJob: 'Jobs/EndJob',
    getFeaturedJobsList: 'Jobs/GetFeaturedJobsList',

    Upload: 'Generic/Upload',
    getImage: 'GetImage/',
    downloadFile: 'DownloadFile/'
  }

}

import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone';
import { RoutesContants } from '../constants/routes.constant';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  private notify = new BehaviorSubject<any>('');
  public notifyObs$ = this.notify.asObservable();
  private toggleMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private _router: Router,
    private _fb: FormBuilder,
    private _modal: NgbModal,
    private _toaster: ToastrService,
    private _ar: ActivatedRoute
  ) { }

  showLoader() {
    this.isLoading.next(true);
  }

  hideLoader() {
    this.isLoading.next(false);
  }

  /*
  Returning a deep copy of provided data to break the reference between variables
*/
  deepCopy(data) {
    return JSON.parse(JSON.stringify(data));
  }

  OpenAlert(type: string, msg: string, title?: string): void {
    type = type.toLowerCase();
    if (type == 'success') {
      this._toaster.success(msg, title);
    } else if (type == 'error') {
      this._toaster.error(msg, title);
    } else if (type == 'warning') {
      this._toaster.warning(msg, title);
    } else if (type == 'info') {
      this._toaster.info(msg, title);
    }
  }

  openModal(template: any, modalOptions: NgbModalOptions = {}) {
    return this._modal.open(template, modalOptions);
  }

  /*
  To create reactive form dynamically.
  @Params: form fields array with all options i.e [{name: 'firstName', isRequired: true}]
  */
  createForm(fieldsArr: any[]) {
    let formObject = {};
    // fieldsArr.forEach(f => {
    //   formObject[f.name] = f.isArray ? this._fb.array(f.defaultValue === null ? [] : [this.createArrayType(f.nestedFieldsArr)]) : [f.defaultValue ? f.defaultValue : '', f.isRequired ? Validators.required : Validators.nullValidator]
    // })
    fieldsArr.forEach(f => {
      if (f.isArray) {
        if (f.defaultValue === null) {
          formObject[f.name] = this._fb.array([]);
        } else {
          formObject[f.name] = this._fb.array([this.createArrayType(f.nestedFieldsArr)]);
        }
      } else if (f.isGroup) {
        let nestGroup = {};
        f.nestedFieldsArr.forEach(nf => {
          nestGroup[nf.name] = [{ value: (nf.defaultValue ? nf.defaultValue : ''), disabled: nf?.disabled ? nf?.disabled : false }, nf.isRequired ? Validators.required : Validators.nullValidator];
        });
        formObject[f.name] = nestGroup;
      } else {
        formObject[f.name] = [{ value: (f.defaultValue ? f.defaultValue : ''), disabled: f?.disabled ? f?.disabled : false }, [f.isRequired ? Validators.required : Validators.nullValidator]];
      }
    })
    return this._fb.group(formObject);
  }
  /*-To create nested reactive form fields dynamically.
  @Params: form fields array with all options i.e [{name: 'firstName', isRequired: true}]
  */
  createArrayType(nestedFieldsArr: any[]) {
    let nestedFormObj = {};
    nestedFieldsArr.forEach(f => {
      nestedFormObj[f.name] = [[{ value: f.defaultValue ? f.defaultValue : '', disabled: f.isDisabled ? true : false }], f.isRequired ? Validators.required : Validators.nullValidator]
    })
    let form = this._fb.group(nestedFormObj);
    return form;
  }
  customCreateForm(fieldsArr: any[]) {
    const formGroup = new FormGroup({});
    fieldsArr.forEach(f => {
      if (f.isArray) {
        if (f.defaultValue === null) {
          // seems to never happen ? because you would need 1 default value for each element of your array, not just one defaultValue.
          formGroup.addControl(f.name, this._fb.array([]));
        } else {
          formGroup.addControl(f.name, this._fb.array([this.customCreateNestedFormGroup(f.nestedFieldsArr)])); // removed the FormArray layer
        }
      }
      else if (f.isGroup) {
        if (f.defaultValue === null) {
          // seems to never happen ? because you would need 1 default value for each element of your array, not just one defaultValue.
          // formObject[f.name] = this.fb.array([]);
          // formGroup.addControl(f.name, []);
        } else {
          formGroup.addControl(f.name, this.customCreateNestedFormGroup(f.nestedFieldsArr)); // removed the FormArray layer
        }
      } else {
        const formControl = new FormControl(f.defaultValue ? f.defaultValue : '', f.isRequired ? Validators.required : Validators.nullValidator);
        formGroup.addControl(f.name, formControl);
      }
    });
    return formGroup;
  }
  customCreateNestedFormGroup(nestedFieldsArr: any[]) {
    const nestedFormGroup = new FormGroup({});
    nestedFieldsArr.forEach(f => {
      const formControl = new FormControl(f.defaultValue ? f.defaultValue : '', f.isRequired ? Validators.required : Validators.nullValidator);
      if (f.isDisabled) {
        formControl.disable();  // moved disabled logic from constructor (not valid)
      }
      nestedFormGroup.addControl(f.name, formControl);
    })
    return nestedFormGroup;
  }

  

  navigate(url: string, id: any = null) {
    if (!id) {
      this._router.navigate([url])
    } else {
      this._router.navigate([url, id])
    }
  }

  /*
   Function to send alerts from one component to another componet
   @Params :
   data: any (we can pass any data/signal to another component)
 */
  notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  /* Getting user token from localstorage. */
  getToken() {
    return localStorage.getItem('token');
  }

  getUserDetails() {
    const userDetails = localStorage.getItem('userDetails');
    if (userDetails) {
      return JSON.parse(userDetails);
    }
    return {};
  }

  isUserLogin() {
    const userDetails = localStorage.getItem('userDetails');
    const token = localStorage.getItem('token');
    return (userDetails && token) ? true : false;
  }

  getUserType() {
    return localStorage.getItem('userType');
  }

  dateControlFormate(date: string) {
    return moment.utc(date).format('YYYY-MM-DD');
  }

  calculateAvgStarRating(data: any) {
    const scoreTotal = (data?.fiveStars * 5) + (data?.fourStars * 4) + (data?.threeStars * 3) + (data?.twoStars * 2) + (data?.oneStars);
    const totalStars = data?.fiveStars + data?.fourStars + data?.threeStars + data?.twoStars + data?.oneStars;
    const avg = Math.floor(scoreTotal / totalStars);
    console.log('avg: ', avg);
    return avg;
  }

  bindCategoryToRoute(CatId) {
    this.notifyOther({ 'findByCatId': CatId });

    if (this._router.url.includes('/find-jobs')) {
      this._router.navigate([], {
        relativeTo: this._ar,
        queryParams: { category: CatId },
        queryParamsHandling: 'merge',
        replaceUrl: true
      })
    } else {
      this._router.navigate([RoutesContants.formRoutes.findJobs], { queryParams: { category: CatId } });
    }
  }

  /* Validate email via patter.
  TODO: Apply reactive email validator to app email input fields
  */
  isValidEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


}

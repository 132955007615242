<!-- Main Header-->
<header class="main-header header-style-two alternate">
  <div class="">
    <!-- Main box -->
    <div class="main-box logged-in">
      <!--Nav Outer -->
      <div class="nav-outer">
        <div class="logo-box">
          <div class="logo">
            <a [routerLink]="formRoutes.home">
              <img src="assets/images/header-logo.png" alt="" title="">

            </a>
          </div>
        </div>
      </div>

      <!-- <div class="header-search">-->
      <!-- <div class="input-group"> -->
      <!-- <div class="input-group-prepend"> -->
      <!-- <button class="btn" type="button" aria-haspopup="true" aria-expanded="false"> -->
      <!-- <i class="fa fa-search"></i> -->
      <!-- </button> -->
      <!-- <div class="dropdown-menu"> -->
      <!-- <a class="dropdown-item" href="#">Option 1</a> -->
      <!-- <a class="dropdown-item" href="#">Option 2</a> -->
      <!-- <a class="dropdown-item" href="#">Option 3</a> -->
      <!-- </div> -->
      <!-- </div> -->
      <!-- <input type="text" class="form-control search-field" placeholder="Search" /> -->
      <!-- </div> -->
      <!-- </div> -->

      <div class="outer-box">
        <!-- Post Login -->
        <div class="btn-box post-login" *ngIf="isLogin">
          <!-- <a [routerLink]="formRoutes.messages" class="btn-style-link call-modal">Messages</a> -->
          <a *ngIf="userType == '2'" [routerLink]="formRoutes.postJob" class="btn-style-link">Post Job</a>

          <div class="dropdown user-dropdown">
            <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <!-- <img src="./assets/images/resource/candidate-3.png" />  -->

              <img
                [src]="userDetails?.image_url ? ImageAPI + userDetails?.image_url?.split('\\').join('||') : './assets/images/resource/candidate-3.png'">
              <span class="user-name">Hello {{ userDetails?. first_name}}</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item"
                [routerLink]="userType == '3' ? formRoutes.editWorkerProfile : formRoutes.editCustomerProfile">Profile</a>
              <a class="dropdown-item" *ngIf="userType == '3'" [routerLink]="formRoutes.workerJobs">View Jobs</a>
              <a class="dropdown-item" *ngIf="userType == '2'" [routerLink]="formRoutes.jobManagement">Manage Jobs</a>
              <a class="dropdown-item" [routerLink]="formRoutes.favoriteList">View Favorites</a>
              <a class="dropdown-item" (click)="logout()">Sign out</a>
            </div>
          </div>
        </div>

        <!-- Pre Login -->
        <div class="btn-box" *ngIf="!isLogin">
          <a [routerLink]="formRoutes.signin" class="btn-style-link call-modal">Post Job</a>
          <a [routerLink]="formRoutes.signup" class="btn-style-one"><span class="btn-title">Signup</span></a>
        </div>
      </div>
    </div>
    <hr />
    <nav class="nav main-menu">
      <ul class="navigation" id="navbar">
        <ng-container *ngIf="userType != '2'">
          <li *ngFor="let c of categories" [class.current]="selectedCategoryId == c?.id"
            (click)="onCategorySelect(c?.id)">
            <span>{{c?.category_name}}</span>
          </li>
        </ng-container>

        <!-- <li class="" id="has-mega-menu">
                    <span>Business</span>
                </li>

                <li class="">
                    <span>Design</span>
                </li>
                <li class="">
                    <span>Home Services</span>
                </li>
                <li class="">
                    <span>Electrician Farming</span>
                </li>
                <li class="">
                    <span>Advertising</span>
                </li>
                <li class="">
                    <span>Branding</span>
                </li>
                <li class="">
                    <span>Cars</span>
                </li> -->

        <!-- Only for Mobile View -->
        <li class="mm-add-listing">
          <a href="add-listing.html" class="theme-btn btn-style-one">Job Post</a>
          <!-- <span>
            <span class="contact-info">
              <span class="phone-num"><span>Call us</span><a href="tel:1234567890">123 456 7890</a></span>
              <span class="address">329 Queensberry Street, North Melbourne VIC <br>3051, Australia.</span>
              <a href="mailto:support@superio.com" class="email">support@superio.com</a>
            </span>
            <span class="social-links">
              <a href="#"><span class="fab fa-facebook-f"></span></a>
              <a href="#"><span class="fab fa-twitter"></span></a>
              <a href="#"><span class="fab fa-instagram"></span></a>
              <a href="#"><span class="fab fa-linkedin-in"></span></a>
            </span>
          </span> -->
        </li>
      </ul>
    </nav>
    <!-- Main Menu End-->
  </div>

  <!-- Mobile Header -->
  <div class="mobile-header">
    <div class="logo"><a href="index.html"><img src="assets/images/logo.jpeg" alt="" title=""></a></div>

    <!--Nav Box-->
    <div class="nav-outer clearfix">

      <div class="outer-box">
        <!-- Post Login -->
        <div class="btn-box post-login" *ngIf="isLogin">
          <a routerLink="/messages" class="btn-style-link call-modal">Messages</a>
          <a *ngIf="userType == '2'" routerLink="/post-job" class="btn-style-link">Post Job</a>

          <div class="dropdown user-dropdown">
            <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <!-- <img src="./assets/images/resource/candidate-3.png" />  -->
              <img
                [src]="userDetails?.image_url ? ImageAPI + userDetails?.image_url?.split('\\').join('||') : './assets/images/slider_avatar.png'">
              <span class="user-name">Hello {{ userDetails?. first_name}}</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" *ngIf="userType == '3'" routerLink="/edit-profile">Profile</a>
              <a class="dropdown-item" *ngIf="userType == '3'" routerLink="/worker-Jobs">View Jobs</a>
              <a class="dropdown-item" *ngIf="userType == '2'" routerLink="/job-management">Manage Jobs</a>
              <a class="dropdown-item" (click)="logout()">Sign out</a>
            </div>
          </div>
        </div>

        <!-- Pre Login -->
        <div class="btn-box" *ngIf="!isLogin">
          <a routerLink="/signin" class="btn-style-link call-modal">Post Job</a>
          <a routerLink="/signup" class="btn-style-one"><span class="btn-title">Signup</span></a>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile Nav -->
  <div id="nav-mobile"></div>
</header>
<!--End Main Header -->